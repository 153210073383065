<template>
    <div class="hotel_map" style="height:600px; width:800px;">
      <l-map ref="map" v-model:zoom="zoom" :center="center">
        <l-tile-layer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          layer-type="base"
          name="OpenStreetMap"
        ></l-tile-layer>
        <l-marker :lat-lng="[55.757131, 37.617114]" @click="openHotel()">
          
        </l-marker>
      </l-map>
    </div>
  </template>
  
  <script>
  import "leaflet/dist/leaflet.css";
  import { LMap, LTileLayer, LMarker, LIcon } from "@vue-leaflet/vue-leaflet";
  
  export default {
    components: {
      LMap,
      LTileLayer,
      LMarker
    },
    data() {
      return {
            zoom: 15,
            center: [55.757131, 37.617114]
        }
    },
    methods: {
        removeMarker(index) {
        this.markers.splice(index, 1);
        },
        addMarker(event) {
        this.markers.push(event.latlng);
        },
        openHotel(){
          window.open('/', '_blank')
        }
    }
  }
  </script>

<style>
.leaflet-control-attribution{
    display: none !important;
}
</style>