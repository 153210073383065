<template>
    <div class="reserved-hotel">
        <div class="hotel-image">
            <img :src="`https://admin.moshot.ru/api/images_mini_rooms/${id}/${image}`" alt="Hotel Image">
        </div>
        <div class="hotel-info">
            <div class="hotel-description">
                <p class="hotel-name"> {{ name }} </p>
                <p> {{ address }} </p>
                <!-- <p> {{ bed }}</p> -->
            </div>
            <div class="hotel-service">
                <div class="hotel-service-pay">
                    <img src="../assets/img/card.png" alt="Payment">
                    <p>Оплата на сайте </p>
                </div>
                <div class="hotel-service-eat">
                    <img src="../assets/img/eat.png" alt="Breakfast">
                    <p>Завтрак <span v-if="eatAvailability === false"> не </span> включен </p>
                </div>
                <div class="hotel-service-wifi">
                    <img src="../assets/img/wifi.png" alt="Wi-Fi">
                    <p><span v-if="wifiAviability === true"> Бесплатный </span> <span v-if="wifiAviability === false">
                            Не бесплатный </span> Wi-Fi</p>
                </div>
            </div>
        </div>
        <div class="hotel-price-and-date">
            <div class="reserve-date">
                <p style="display: inline;">Дата бронирования: </p>
                <p style="display: inline;"> {{ formatDate(new Date(date)) }}</p>
            </div>
            <div class="reserve-date">
                <p>Даты заезда и выезда:</p>
                <p> {{ formatDate(new Date(dateCheckIn)) }}–{{ formatDate(new Date(dateCheckOut)) }}</p>
            </div>
            <div class="hotel-price" v-if="agent_sale > 0 && this.$store.state.user_info.role == 'agent'">
                <p style="font-size: 1.2em; text-decoration: line-through;" class="text-danger"> {{ Number(price*100/(100-agent_sale)).toLocaleString('ru') }}₽</p>
                <p style="font-size: 1em;" class="text-danger">(-{{ agent_sale }}%)</p>
                <p>{{ Number(price).toLocaleString('ru') }}₽</p>
            </div>
            <div class="hotel-price" v-else>
                <p> {{ Number(price).toLocaleString('ru') }}₽</p>
            </div>
            <div class="hotel-sleep">
                <p> {{ nights }} <span v-if="nights == 1">ночь</span><span v-if="nights < 5 && nights !== 1">
                        ночи</span><span v-if="nights >= 5"> ночей</span><span v-if="guests > 0">, {{ guests }} <span v-if="guests == 1"> гость
                    </span> <span v-if="guests < 5 && guests !== 1"> гостя </span> <span v-if="guests >= 5"> гостей
                    </span></span></p>
            </div>
        </div>
        <div class="reserved-true" v-if="reserved === true">
            <p>Активно</p>
        </div>
        <div class="reserved-in-process" v-if="reserved === false">
            <p>Закрытый</p>
        </div>
        <div class="reserved-false" v-if="reserved === null">
            <p>Закрыто</p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        reserved: Boolean,
        image: String,
        name: String,
        bed: String,
        eatAvailability: Boolean,
        wifiAviability: Boolean,
        price: String,
        nights: Number,
        guests: Number,
        dateCheckIn: String,
        dateCheckOut: String,
        id: Number,
        address: String,
        agent_sale: Number,
        date: String
    },
    methods: {
        getImageSrc(image) {
            try {
                return require(`@/assets/img/${image}`);
            } catch (error) {
                throw new Error(error);
            }
        },
        formatDate(date) {
            var dd = date.getDate();
            if (dd < 10) dd = '0' + dd;

            var mm = date.getMonth() + 1;
            if (mm < 10) mm = '0' + mm;

            var yy = date.getFullYear()

            return dd + '.' + mm + '.' + yy;
        }
    }
}
</script>