<template>
  <div class="container-main">
    <div class="header">
      <div class="header-wrapper">
        <div class="header-booking">
          <div class="booking-heading">
            <p>Лучшие отели по лучшим ценам в Москве!</p>
          </div>
          <form class="booking-filters" style="z-index: 2;" @submit.prevent="openWebsite()">
            <!--<div class="filter-check-in">
              <input placeholder="Заезд">
              <button>
                <img src="../assets/img/filter-calendar.png" alt="">
              </button>
            </div>
            <div class="filter-exit">
              <input placeholder="Выезд">
              <button>
                <img src="../assets/img/filter-calendar.png" alt="">
              </button>
            </div>-->
            <div class="filter-check-in mb-1">
              <div class="input-group" style="border-color: #c8a54a;">
                    <!-- <input type="text" class="form-control" @click="showCalendar()" :class="{'d-none': calendarShow}" style="background-color: rgba(0, 0, 0, 0.3); color: #fff;" autocomplete="off"><span class="input-group-text" :class="{'d-none': calendarShow}" style="border: 0; background-color: rgba(0, 0, 0, 0.3); color: #fff;"><label><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar3" viewBox="0 0 16 16"><path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z"></path><path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"></path></svg></label></span> -->
                    <input type="text" class="form-control pageFilter__daterange js-daterangepicker" style="background-color: rgba(0, 0, 0, 0.3); position: relative; color: #fff;" id="period_a0acfa46" value="" placeholder="Введите даты" autocomplete="off"><span class="input-group-text" style="border: 0; background-color: rgba(0, 0, 0, 0.3); color: #fff;"><label for="period_a0acfa46"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar3" viewBox="0 0 16 16"><path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z"></path><path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"></path></svg></label></span></div>
                    <input type="hidden" name="filter[start_period]" class="js-daterangepicker__start" value="10.02.2024">
                    <input type="hidden" name="filter[end_period]" class="js-daterangepicker__end" value="10.03.2024">
            </div>
            <!--<div class="filter-guests">
              <input placeholder="Количество гостей">
              <button>
                <img src="../assets/img/filter-arrow.png" alt="">
              </button>
            </div>-->

            <details class="custom-select filter-guests mb-1" id="guestsSelect" style="background-color: rgba(0, 0, 0, 0.3); color: #fff;">
                <summary class="radios">
                    <input type="radio" name="item" id="default_guest" title="Количество гостей" value="0" checked v-model="filter_data.guests" onclick="document.querySelector('#guestsSelect').open = !document.querySelector('#guestsSelect').open" style="color: #fff;">
                    <input type="radio" name="item" id="guest1" style="color: #fff;" title="1 гость" value="1" v-model="filter_data.guests" onclick="document.querySelector('#guestsSelect').open = !document.querySelector('#guestsSelect').open">
                    <input type="radio" name="item" id="guest2" style="color: #fff;" title="2 гостя" value="2" v-model="filter_data.guests" onclick="document.querySelector('#guestsSelect').open = !document.querySelector('#guestsSelect').open">
                    <input type="radio" name="item" id="guest3" style="color: #fff;" title="3 гостя" value="3" v-model="filter_data.guests" onclick="document.querySelector('#guestsSelect').open = !document.querySelector('#guestsSelect').open">
                    <input type="radio" name="item" id="guest4" style="color: #fff;" title="4 гостя" value="4" v-model="filter_data.guests" onclick="document.querySelector('#guestsSelect').open = !document.querySelector('#guestsSelect').open">
                    <input type="radio" name="item" id="guest5" style="color: #fff;" title="5+ гостей" value="5" v-model="filter_data.guests" onclick="document.querySelector('#guestsSelect').open = !document.querySelector('#guestsSelect').open">
                </summary>
                <ul class="list" style="color: #000;">
                    <li>
                        <label for="guest1">
                            1 гость
                            <span></span>
                        </label>
                    </li>
                    <li>
                        <label for="guest2">2 гостя</label>
                    </li>
                    <li>
                        <label for="guest3">3 гостя</label>
                    </li>
                    <li>
                        <label for="guest4">4 гостя</label>
                    </li>
                    <li>
                        <label for="guest5">5+ гостей</label>
                    </li>
                </ul>
            </details>

            <!--<div class="filter-main">
              <input placeholder="Фильтры">
              <button>
                <img src="../assets/img/filter-sliders.png" alt="">
              </button>
            </div>-->

            <div style="position: relative;" class="mb-1">
                    <button class="filter-main" type="button" data-bs-toggle="collapse" data-bs-target="#service_params" style="background-color: rgba(0, 0, 0, 0.3); color: #fff;">
                        <p style="color: #fff;">Фильтры</p>
                        <img src="../assets/img/filter_icon.svg" alt="" style="color: #fff !important; fill: #fff !important;">
                    </button>
                    <div class="checkbox-collapse-list collapse" id="service_params">
                        <div class="card card-body" >

                            <details class="custom-select moscow-district mb-3" id="disSelect">
                                <summary class="radios">
                                    <input type="radio" name="dis" id="default_moscow" title="Все районы Москвы" value="Все" v-model="filter_data.district" onclick="document.querySelector('#disSelect').open = !document.querySelector('#disSelect').open">
                                    <input type="radio" name="dis" :id="`dis${index}`" :value="dis" :title="dis" v-model="filter_data.district" v-for="(dis, index) in districts" :key="index" onclick="document.querySelector('#disSelect').open = !document.querySelector('#disSelect').open">
                                </summary>
                                <ul class="list">
                                    <li>
                                        <label for="default_moscow">Все районы Москвы</label>
                                    </li>
                                    <li v-for="(dis, index) in districts" :key="index">
                                        <label :for="`dis${index}`">{{ dis }}</label>
                                    </li>
                                </ul>
                            </details>

                            
                                    <div class="card card-body mb-3">
                                            <div class="mb-3">
                                                <label for="priceFromInput" style="font-size: 0.9em;" class="form-label me-3">Цена от</label>
                                                <div class="d-flex">
                                                    <input type="number" v-model="filter_data.prices.minPrice" class="form-control px-2 py-1" id="priceFromInput">
                                                    <label for="priceFromInput" class="form-label ms-2 my-auto">₽</label>
                                                </div>
                                            </div>
                                            <div class="">
                                                <label for="priceToInput" style="font-size: 0.9em;" class="form-label me-3">Цена до</label>
                                                <div class="d-flex">
                                                    <input type="number" v-model="filter_data.prices.maxPrice" class="form-control px-2 py-1" id="priceToInput">
                                                    <label for="priceToInput" class="form-label ms-2 my-auto">₽</label>
                                                </div>
                                            </div>
                                    </div>
                                

                            <div style="position: relative;" class="filters-container-relative mb-3">
                                <div class="stars-filter" type="button" data-bs-toggle="collapse" data-bs-target="#stars_params">
                                    <div class="stars-filter-text d-flex flex-row mb-0">
                                        <p>Количество звезд </p>
                                        <img src="../assets/img/star.png" alt="" style="width: 1em; height: 1em;">
                                    </div>
                                </div>
                                <div class="checkbox-collapse-list collapse" id="stars_params">
                                    <div class="card card-body" >
                                        <!--<div class="form-check d-flex mb-2">
                                            <input class="form-check-input my-auto me-2 p-0" type="checkbox" id="0_stars" value="0" v-model="filter_data.stars">
                                            <label class="form-check-label" style="margin-top: 1.5px;" for="0_stars">
                                                Без звезд
                                            </label>
                                        </div>-->
                                        <div class="form-check d-flex mb-2" v-for="(stars, index) in [3, 4, 5]" :key="index">
                                            <input class="form-check-input my-auto me-1 p-0" type="checkbox" :id="`${stars}_stars`" :value="stars" style="height: 1em !important; font-size: 1em;" v-model="filter_data.stars">
                                            <label class="form-check-label d-flex my-auto" :for="`${stars}_stars`">
                                                <img v-for="star in stars" :key="star" src="../assets/img/star.png" alt="" style="width: 1em; height: 1em; margin-right: 5px;">
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div style="position: relative;" class="filters-container-relative mb-3">
                                <div class="stars-filter" type="button" data-bs-toggle="collapse" data-bs-target="#bed_types_params">
                                    <div class="stars-filter-text d-flex flex-row mb-0">
                                        <p>Тип кроватей </p>
                                        
                                    </div>
                                </div>
                                <div class="checkbox-collapse-list collapse" id="bed_types_params">
                                    <div class="card card-body" >
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="bed_types" id="bed_types1" value="Двуспальная кровать" style="height: 1em !important; font-size: 1em;" v-model="filter_data.bed_types">
                                            <label class="form-check-label" for="bed_types1">
                                                Двуспальная кровать
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="bed_types" value="Раздельные кровати" id="bed_types2" style="height: 1em !important; font-size: 1em;" v-model="filter_data.bed_types">
                                            <label class="form-check-label" for="bed_types2">
                                                Раздельные кровати
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-check" v-for="(service, index) in servicesList" :key="index">
                                <input class="form-check-input" type="checkbox" :id="`${index}_service`" :value="service" v-model="filter_data.services" style="height: 1em !important; font-size: 1em;">
                                <label class="form-check-label" :for="`${index}_service`">
                                    {{ service }}
                                </label>
                            </div>

                            <div class="d-flex flex-column mt-3">
                                <input type="text" v-model="filter_data.search" placeholder="Название отеля или номера" class="form-control px-2 h-100" style="font-size: 1em; padding-top: 10px !important; padding-bottom: 10px !important;">
                                <button class="filter-found w-100 h-100 py-3 mt-2" style="background-color: #0A0C20 !important; font-size: 1.2em;">Поиск</button>
                            </div>
                        </div>
                    </div>
                </div>

            <button class="filter-found"  style="background-color: rgba(0, 0, 0, 0.3) !important;">Найти</button>
              </form>
        </div>
      </div>
    </div>
    <main>
      <div class="hotel-offers main-hotel-offers">
        <div class="offer w-100">
          <p style="font-weight: 600; color: #703C05;" class="main-page-daily-offer">Лучшее предложение дня</p>
          <a :href="'/hotels/'+sale_rooms['day'].hotel_id" style="color: initial; text-decoration: none;"><HotelCard :key="index" :room="sale_rooms['day']" :nights="1" :guests="sale_rooms['day'].min_guests" /></a>
        </div>
        <div class="offer w-100">
          <p style="font-weight: 600; color: #703C05;">Лучшее предложение недели</p>
          <a :href="'/hotels/'+sale_rooms['week'].hotel_id" style="color: initial; text-decoration: none;"><HotelCard :key="index" :room="sale_rooms['week']" :nights="1" :guests="sale_rooms['week'].min_guests" /></a>
        </div>
        <div class="offer w-100">
          <p style="font-weight: 600; color: #703C05;">Лучшее предложение месяца</p>
          <a :href="'/hotels/'+sale_rooms['month'].hotel_id" style="color: initial; text-decoration: none;"><HotelCard :key="index" :room="sale_rooms['month']" :nights="1" :guests="sale_rooms['month'].min_guests" /></a>
        </div>
      </div>
      <div id="lk_choose"></div>
      <div class="personal-accounts">
        <div class="personal-account" :style="guestAccountStyle">
          <p>Личный кабинет гостя</p>
          <a href='/auth?role=user'>Войти</a>
        </div>
        <div class="personal-account" :style="agentAccountStyle">
          <p>Личный кабинет агента</p>
          <a href='/auth?role=agent'>Войти</a>
        </div>
      </div>
      <div class="hotels-block">
        <button  @click="openWebsite"> Отели <!--<img src="../assets/img/hotels-vector.png" alt="">--></button>
        <div class="hotels-catalog">
          <CatalogHotel v-for="(hotel, index) in hotels" :key="index" :hotel_id="Number(hotel.id)" :image="hotel.img" :stars="Number(hotel.stars)"
            :name="hotel.title" />
        </div>
        <a class="show-all-catalog" style="cursor: pointer;" @click="loadAllHotels()" id="loadHotelsBtn">Посмотреть все предложения</a>
      </div>
      <div class="block-company-logo">
        <img src="../assets/img/block-company-logo.png" alt="">
      </div>

      <div class="container">
        <div class="map">
          <!-- <iframe
            src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d2396.889919547388!2d37.623533138211855!3d55.757803754581666!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sru!2sru!4v1723597973860!5m2!1sru!2sru"
            width="100%" height="655" style="border:0;" loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe> -->
            <div style="height:655px; width:100%;">
                <l-map ref="map" v-model:zoom="zoom" :center="[55.752004, 37.617734]">
                    <l-tile-layer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    layer-type="base"
                    name="OpenStreetMap"
                    ></l-tile-layer>
                    <l-marker v-for="item in hotels_map" :lat-lng="item.map.split(', ')" @click="openHotel(item.id)">
                    
                    </l-marker>
                </l-map>
            </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import CatalogHotel from '@/components/CatalogHotel.vue';
import HotelCard from '@/components/HotelCardMain.vue';
import guestAccountImage from '@/assets/img/personal-account-agent.jpeg';
import agentAccountImage from '@/assets/img/personal-account-agent-old.png';
import axios from 'axios';
import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer, LMarker, LIcon } from "@vue-leaflet/vue-leaflet";

export default {
  components: {
    HotelCard,
    CatalogHotel,
    LMap,
    LTileLayer,
    LMarker
  },
  data() {
    return {
      guestAccountStyle: {
        backgroundImage: `url(${guestAccountImage})`
      },
      agentAccountStyle: {
        backgroundImage: `url(${agentAccountImage})`
      },
      dailyHotels: [
        {
          image: 'four-seasons.png',
          name: 'Four Seasons Hotel Moscow 5',
          address: 'ул. Охотный Ряд, 2, Москва (этаж 1)',
          stars: 3,
          canPay: 'на сайте',
          eatAvailability: true,
          wifiAviability: true,
          reviews: '69 отзывов',
          rating: 9.1,
          price: '6 279 ₽',
          nights: 1,
          guests: 1
        },
      ],
      weeklyHotels: [
        {
          image: 'nationale.png',
          name: 'Националь',
          address: 'Моховая ул., 15/1с1, Москва',
          stars: 5,
          canPay: 'на сайте',
          eatAvailability: true,
          wifiAviability: true,
          reviews: '50 отзывов',
          rating: 8.0,
          price: '10 235 ₽',
          nights: 1,
          guests: 1
        },
      ],
      monthlyHotels: [
        {
          image: 'novotel.png',
          name: 'Novotel',
          address: 'Пресненская наб., 2, Москва (этаж 2)',
          stars: 4,
          canPay: 'на сайте',
          eatAvailability: true,
          wifiAviability: true,
          reviews: '32 отзыва',
          rating: 8.2,
          price: '13 325 ₽',
          nights: 1,
          guests: 1
        },
      ],
      hotelsCatalog: [
        {
          image: 'azimut.png',
          stars: 3,
          name: 'AZIMUT Сити Отель Смоленская Москва'
        },
        {
          image: 'arbat-stars.png',
          stars: 5,
          name: 'Звезды Арбата'
        },
        {
          image: 'Cartlon.png',
          stars: 5,
          name: 'The Carlton Moscow'
        },
        {
          image: 'Pentahotel.png',
          stars: 4,
          name: 'Pentahotel Moscow, Arbat'
        },
        {
          image: 'Cartlon.png',
          stars: 5,
          name: 'The Carlton Moscow'
        },
        {
          image: 'Pentahotel.png',
          stars: 4,
          name: 'Pentahotel Moscow, Arbat'
        },
        {
          image: 'azimut.png',
          stars: 3,
          name: 'AZIMUT Сити Отель Смоленская Москва'
        },
        {
          image: 'arbat-stars.png',
          stars: 5,
          name: 'Звезды Арбата'
        },
      ],
      sale_rooms: {
        'day': {
          hotel_id: 0,
          img: []
        },
        'week': {
          hotel_id: 0,
          img: []
        },
        'month': {
          hotel_id: 0,
          img: []
        }
      },
      hotels: [],
      servicesList: [
          'Wi-Fi',
          'Автостоянка/парковка',
          'Сауна',
          'Фен',
          'Бар/ресторан',
          'Полотенца',
          'Туалетные принадлежности',
          'Душ',
          'Халат',
          'Тапочки',
          'Гигиенический душ',
          'Электрический чайник',
          'Холодильник',
          'Веранда',
          'Терраса',
          'Охраняемая территория',
          'Обеденная зона на улице',
          'Завтрак включен'
      ],
      filter_data: {
          services: [],
          guests: "0",
          district: 'Все',
          prices: {
              minPrice: 0,
              maxPrice: 0
          },
          stars: [],
          bed_types: "",
          search: ""
      },
      districts: [
                'Центр',
                'Север',
                'Северо-запад',
                'Северо-восток',
                'Запад',
                'Юг',
                'Юго-запад',
                'Юго-восток',
                'Восток'
            ],
      zoom: 15,
      hotels_map: [],
      calendarShow: false
    }
  },
  methods: {
    openWebsite() {
      this.$router.push(`/hotels?search=${this.filter_data.search}&start=${this.filter_data.start}&end=${this.filter_data.end}&services=${JSON.stringify(this.filter_data.services)}&guests=${this.filter_data.guests}&district=${this.filter_data.district}&minPrice=${this.filter_data.prices.minPrice}&maxPrice=${this.filter_data.prices.maxPrice}&stars=${JSON.stringify(this.filter_data.stars)}&bed_types=${this.filter_data.bed_types}`).then(() => {
        window.scrollTo(0, 0);
      });
    },
    async loadData(){
      await axios.get('/api/get_sale_rooms.php').then((response)=>{
        this.sale_rooms = response.data
      })
        axios.get(`/api/get_hotels.php?limit=6`).then((response)=>{
          this.hotels = response.data
          console.log(this.hotels)
        })
    },
    async loadAllHotels(){
      document.querySelector('#loadHotelsBtn').style.display = 'none';
      const response = await axios.get(`/api/get_hotels.php`)
      this.hotels = response.data
    },
    async loadMapData(){
      const response = await axios.get(`/api/get_hotels.php`)
      this.hotels_map = response.data
      console.log('Отели на карте:')
      console.log(this.hotels_map)
    },
    openHotel(hotel_id){
      window.open(`/hotels/${hotel_id}`, '_blank')
    },

    showCalendar(){
      this.calendarShow = true

    }
  },

  created(){
    this.filter_data.end =  moment().add(2, 'days').format('YYYY-MM-DD');
    this.filter_data.start = moment().add(1, 'days').format('YYYY-MM-DD');
    this.loadData()
    this.loadMapData()

//     axios.post('https://reservationsteps.ru/bookings/post/c5c2f136-8109-42f1-a02d-0efc0252e310', {
//       servicemode: 0,
// firstroom: 0,
// dfrom: '17-10-2024',
// dto: '18-10-2024',
// planId: 622572,
// adults: 1,
// children: '',
// promoCode: '',
// roomTypes: {"495744":{"c":1,"bv":3}},
// roomtypeUpgrade: '',
// services: '',
// lang: 'ru',
// warrantyType: 'no',
// orderid: '',
// moneywall_enabled: 0,
// mobile_id: 0,
// guarantee: 0,
// customer: {
//   name: 'Андрей',
// surname: 'Иии',
// phone: '+7(999)999-99-99',
// email: 'test@mail.ru',
// notes: '',
// }
//     })
  },

  mounted(){
       
        let start = moment().add(1, 'days');
        let end = moment().add(2, 'days');

        // function cb(start, end) {
        //     $('.js-daterangepicker span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
        // }

        $('.js-daterangepicker').daterangepicker({
        "locale": {
            "format": "DD.MM.YYYY",
            "separator": " - ",
            "applyLabel": "Сохранить",
            "cancelLabel": "Назад",
            "daysOfWeek": [
                "Вс",
                "Пн",
                "Вт",
                "Ср",
                "Чт",
                "Пт",
                "Сб"
            ],
            "monthNames": [
                "Январь",
                "Февраль",
                "Март",
                "Апрель",
                "Май",
                "Июнь",
                "Июль",
                "Август",
                "Сентябрь",
                "Октябрь",
                "Ноябрь",
                "Декабрь"
            ],
            "firstDay": 1
        },
        
        opens: 'left',
        autoUpdateInput: false,
        startDate: start,
        endDate: end,
        minDate: moment(),
        }, (start, end) => {
            $('.js-daterangepicker').data('daterangepicker').startDate = start
            $('.js-daterangepicker').data('daterangepicker').endDate = end
            this.filter_data.start = start.format('YYYY-MM-DD')
            this.filter_data.end =  end.format('YYYY-MM-DD')
        });


        //cb(start, end);

        $('#period_a0acfa46').on('apply.daterangepicker', function(ev, picker) {
            $(this).val(picker.startDate.format('DD.MM.YYYY') + ' - ' + picker.endDate.format('DD.MM.YYYY'));
        });

        $('.hotel-booking-button a').removeAttr('data-bs-toggle')
        $('.hotel-booking-button a').removeAttr('data-bs-target')
      
    }
  
    
}
</script>

<style lang="scss">
#period_a0acfa46::placeholder{
  color: #fff;
  opacity: 1;
}

.main-hotel-offers .carousel-item img{
  border: 2px solid #8D5110 !important;
}

.main-hotel-offers .hotel-booking-button a{
  background: #8D5110 !important;
}

</style>