<template>
    <div class="offer">
      <div class="hotel">
        <a :href="`/hotels/${room.hotel_id}`" :id="`rooms_img_carousel_${room.id}`" class="carousel slide carousel_room_img" data-bs-ride="false">
          <div class="carousel-indicators">
            <button type="button" :data-bs-target="`#rooms_img_carousel_${room.id}`" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" v-for="(img, index) in room.img.filter((x)=>x!==room.img[0])" :key="index" :data-bs-target="`#rooms_img_carousel_${room.id}`" :data-bs-slide-to="index+1" aria-current="true" aria-label="Slide 1"></button>
          </div>
          <div class="carousel-inner">
            <!--<div class="carousel-item active">
              <img :src="`https://admin.moshot.ru/api/images_mini_hotels/${room.hotel_id}/${room.img[0]}`" alt="Hotel Image">
            </div>
            <div class="carousel-item" v-for="(img, index) in room.img.filter((x)=>x!==room.img[0])" :key="index">
              <img :src="`https://admin.moshot.ru/api/images_mini_rooms/${room.id}/${img}`" alt="Room Image">
            </div>-->
            <div class="carousel-item" v-for="(img, index) in room.img" :key="index" :class="{'active': index == 0}">
              <img :src="`https://admin.moshot.ru/api/images_mini_rooms/${room.id}/${img}`" alt="Room Image">
            </div>
          </div>
          <button class="carousel-control-prev" type="button" :data-bs-target="`#rooms_img_carousel_${room.id}`" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" :data-bs-target="`#rooms_img_carousel_${room.id}`" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </a>
              
        <a style="color: initial; text-decoration: none;" :href="`/hotels/${room.hotel_id}`" class="hotel-info">
          <div class="hotel-description">
            <p class="hotel-name">{{ room.room_title }} {{ room.title }}</p>
            <p>{{ room.address }}</p>
            <div class="hotel-stars">
              <img v-for="n in Number(room.stars)" :key="n" src="../assets/img/star.png" alt="Star">
            </div>
          </div>
          <div class="hotel-service">
            <div class="hotel-service-pay">
              <img src="../assets/img/card.png" alt="Payment">
              <p>Оплата на сайте </p>
            </div>
            <div class="hotel-service-eat">
              <img src="../assets/img/eat.png" alt="Breakfast">
              <p>Завтрак <span v-if="room.breakfast == '0'"> не </span> включен </p>
            </div>
            <div class="hotel-service-wifi">
              <img src="../assets/img/wifi.png" alt="Wi-Fi">
              <p><span v-if="room.wifi == '1'"> Бесплатный Wi-Fi</span></p>
            </div>
          </div>
        </a>
        <div class="hotel-booking-visible">
          <div class="hotel-booking">
            <div class="hotel-booking-top">
              <!--<p>{{ room.reviews_count }} отзывов</p>
              <p class="hotel-rate">{{ room.map_score }}</p>-->
            </div>
            <div class="hotel-booking-bottom">
              <div class="hotel-booking-price">
              <p>{{ (room.price).toLocaleString('ru') }}₽</p>
              </div>
              <div class="hotel-booking-sleep">
                <p> {{ nights }} <span v-if="nights == 1"> ночь</span><span v-if="nights < 5 && nights > 1"> ночи</span><span v-if="nights >= 5">ночей</span><span v-if="guests > 0">, {{ guests }} <span v-if="guests == 1"> гость </span> <span v-if="guests < 5 && guests > 1"> гостя </span> <span v-if="guests >= 5"> гостей </span></span></p>
              </div>
              <div class="hotel-booking-button">
                <a :href="`/hotels/${room.hotel_id}`">Забронировать</a>
              </div>
            </div>
          </div>
        </div>
        <div class="hotel-booking-invisible">
          <div class="hotel-booking-button">
            <a :href="`/hotels/${room.hotel_id}`">Забронировать</a>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>  
  export default {
    props: {
      room: Object,
      nights: Number,
      guests: Number,
      start: String,
      end: String
    },
    data(){
      return{
      }
    }
  }
  </script>