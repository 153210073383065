<template>
    <div class="container">
          <form class="auth-block container-find" id="auth-form" style="padding-bottom: 100px;" @submit.prevent="sendData()">
  
            <h2>Авторизация</h2>
            <p style="font-size: 0.8em; text-align: center;">У вас нет личного кабинета? <br> <a :href="`/reg?role=${$route.query.role}`" style="color: #000 !important;">Зарегистрируйтесь сейчас</a></p>
  
              <div class="form-floating">
                <input type="email" class="form-control mt-3" id="mail-booking" required v-model="form_data.mail">
                <label for="mail-booking">E-mail</label>
              </div>
              
              <div class="form-floating">
                <input type="password" class="form-control mt-3" id="form-password" required v-model="form_data.password">
                <label for="form-password">Пароль</label>
              </div>
  
              <div class="hotel-booking-button-modal mt-3">
                <button>Войти</button>
              </div>
          </form>
  
    </div>
  
  </template>
  
  <script>
  import axios from 'axios';
  
  
  export default{
    data(){
      return {
        form_data: {}
      }
    },
    methods: {
      sendData(){
        axios.post('/api/auth.php', this.form_data).then((response)=>{
            console.log(response.data)
          if(response.data == 0){
            alert('Пользователь не найден, повторите попытку!')
            window.location.reload()
          }
          else{
            if(response.data){
                
                if(this.$route.query.role == 'user'){
                  if(response.data.role == 'user'){
                    this.$store.commit('authUser', response.data)
                     window.location.href = '/lk'
                  }
                  else{
                    alert('Пользователь не найден, повторите попытку!')
                    window.location.reload()
                  }
                } 
                else if(this.$route.query.role == 'agent'){
                  if(response.data.role == 'agent'){
                    this.$store.commit('authUser', response.data)
                     window.location.href = '/lk_agent'
                  }
                  else{
                    alert('Пользователь не найден, повторите попытку!')
                    window.location.reload()
                  }
                }
            }
            else{
                alert('Произошла ошибка, повторите попытку!')
                window.location.reload()
            }
          }
        })
      }
    },

    created(){
        if(this.$store.state.user == true && this.$store.state.user_info.role == 'user' && this.$route.query.role == 'user') window.location.href='/lk' 
        else if(this.$store.state.user == true && this.$store.state.user_info.role == 'agent' && this.$route.query.role == 'agent') window.location.href='/lk_agent' 
    }
  }
  </script>