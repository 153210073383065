<template>
  <div class="modal fade" :id="`booking_${room.id}`" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Забронировать {{ room.room_title }}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form class="booking-block" id="booking-form">
          <p>Заезд: {{ new Date(filter_data.start).toLocaleString("ru", options = {year: 'numeric',month: 'long',day: 'numeric'}) }}</p>
          <p>Выезд: {{ new Date(filter_data.end).toLocaleString("ru", options = {year: 'numeric',month: 'long',day: 'numeric',}) }}</p>
          <!--<div class="date">  
              <div class="input-group">
              <input type="text" disabled class="form-control pageFilter__daterange js-daterangepicker-booking" id="period_a0acfa46" autocomplete="off"><span class="input-group-text d-none" style="border: 0;"><label for="period_a0acfa46"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar3" viewBox="0 0 16 16"><path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z"></path><path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"></path></svg></label></span></div>
              <input type="hidden" name="filter[start_period]" class="js-daterangepicker__start" value="10.02.2024">
              <input type="hidden" name="filter[end_period]" class="js-daterangepicker__end" value="10.03.2024">
          </div>-->

          <select class="form-select mt-3" v-model="filter_data.guests">
            <option value="0" selected disabled>Количество гостей</option>
            <option value="1">1 гость</option>
            <option value="2">2 гостя</option>
            <option value="3">3 гостя</option>
            <option value="4">4 гостя</option>
            <option value="5">5+ гостей</option>
          </select>

            <div class="form-floating">
              <input type="text" class="form-control mt-3" id="name-booking" required v-model="filter_data.name">
              <label for="name-booking">Имя</label>
            </div>

            <div class="form-floating">
              <input type="text" class="form-control mt-3" id="surname-booking" required v-model="filter_data.surname">
              <label for="surname-booking">Фамилия</label>
            </div>

            <div class="form-floating">
              <input type="email" class="form-control mt-3" :disabled="$store.state.user"  id="mail-booking" required v-model="filter_data.mail" autocomplete="false">
              <label for="mail-booking">E-mail</label>
            </div>

            <div class="form-floating">
              <input type="tel" class="form-control mt-3" :id="`phone-booking${room.id}`" placeholder="+7(___)___-__-__" required v-model="filter_data.phone">
              <label for="phone-booking">Номер телефона</label>
            </div>

            <div class="form-floating d-none">
              <input type="text" class="form-control mt-3" id="licence-booking" required v-model="filter_data.licence">
              <label for="licence-booking">Серия, номер паспорта</label>
            </div>

            <!-- <div class="form-floating" v-if="!$store.state.user">
              <input type="password" class="form-control mt-3" id="form-password" required v-model="filter_data.password">
              <label for="form-password">Придумайте пароль для личного кабинета</label>
            </div> -->

            <div class="form-floating">
              <input type="text" :disabled="filter_data.agent > 0" class="form-control mt-3" id="promocode-booking" required v-model="promocode">
              <label for="promocode-booking">Промокод</label>
            </div>

            <div class="hotel-booking-button-modal mt-2" v-if="filter_data.agent == 0">
              <a @click="usePromocode()">Активировать</a>
            </div>
            
        </form>
      </div>
      <div class="modal-footer">
        <div class="d-flex justify-content-between w-100">
           <div class="ms-2 my-auto">
            <div class="d-flex" v-if="filter_data.agent>0">
              <h3 class="mb-0 fw-bold" >{{ parseInt(room.price*((100 - filter_data.agent_sale)/100)).toLocaleString('ru') }}₽</h3>
              <h5 class="mb-0 fw-bold text-danger d-block ms-2" v-if="filter_data.agent_sale > 0" style="text-decoration: line-through;">{{ (room.price).toLocaleString('ru') }}₽</h5>
            </div>
            <div class="d-flex" v-if="$store.state.user && filter_data.agent == 0 && $store.state.user_info.role == 'user'">
              <h3 class="mb-0 fw-bold" >{{ parseInt(room.price*((100 - $store.state.user_info.sale)/100)).toLocaleString('ru') }}₽</h3>
              <h5 class="mb-0 fw-bold text-danger d-block ms-2" style="text-decoration: line-through;">{{ (room.price).toLocaleString('ru') }}₽</h5>
            </div>
            <h3 class="mb-0 fw-bold" v-if="$store.state.user_info.role !== 'user' && filter_data.agent == 0">{{ (room.price).toLocaleString('ru') }}₽</h3>
          </div>
          <div class="hotel-booking-button-modal">
            <a @click="sendData()">Забронировать</a>
          </div>
        </div>
       
        
      </div>
    </div>
  </div>
</div>

    <div class="possible-number-container">
        <div class="possible-number-block">
            <div class="possible-number-image">
                <div :id="`rooms_img_carousel_${room.id}`" class="carousel slide carousel_room_img" data-bs-ride="false">
                    <div class="carousel-indicators">
                    <button type="button" :data-bs-target="`#rooms_img_carousel_${room.id}`" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" v-for="(img, index) in room.img.filter((x)=>x!==room.img[0])" :key="index" :data-bs-target="`#rooms_img_carousel_${room.id}`" :data-bs-slide-to="index+1" aria-current="true" aria-label="Slide 1"></button>
                    </div>
                    <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img :src="`https://admin.moshot.ru/api/images_mini_rooms/${room.id}/${room.img[0]}`" alt="Room Image">
                    </div>
                    <div class="carousel-item" v-for="(img, index) in room.img.filter((x)=>x!==room.img[0])" :key="index">
                        <img :src="`https://admin.moshot.ru/api/images_mini_rooms/${room.id}/${img}`" alt="Room Image">
                    </div>
                    </div>
                    <button class="carousel-control-prev" type="button" :data-bs-target="`#rooms_img_carousel_${room.id}`" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" :data-bs-target="`#rooms_img_carousel_${room.id}`" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
            <div class="possible-number-info">
                <div class="possible-number-name">
                    <p> {{ room.title }} </p>
                    <p class="possible-number-beds"> {{ room.bed }} </p>
                </div>
                <div class="possible-number-service">
                    <div class="service">
                        <img src="../assets/img/card.png" alt="">
                        <p>Оплата картой на сайте</p>
                    </div>
                    <div class="service">
                        <img src="../assets/img/eat.png" alt="">
                        <p>Завтрак <span v-if="!room.services.includes('Завтрак включен')"> не </span> включен </p>
                    </div>
                    <div class="service">
                        <img src="../assets/img/wifi.png" alt="">
                        <p><span v-if="room.services.includes('Wi-Fi')"> Бесплатный </span> <span v-else> Не бесплатный </span> Wi-Fi</p>
                    </div>
                </div>
                <button class="button-number-details visible btn btn-primary" type="button" data-bs-toggle="collapse"
                    :data-bs-target="'#details_' + room.id" aria-expanded="false" aria-controls="collapseExample"
                    @click="toggleRotateNumber">
                    <p>Подробнее о номере</p>
                    <img src="../assets/img/number-details.png" alt="" :class="{ 'rotate': isRotateNumber }">
                </button>
            </div>
            <div class="possible-number-price">
                <p class="price"> {{ (room.price).toLocaleString('ru') }} ₽</p>
                <p> {{ nights }} <span v-if="nights == 1">ночь</span><span v-if="nights < 5 && nights !== 1">ночи</span><span v-if="nights >= 5">ночей</span>
                    <span v-if="guests > 0">, {{ guests }} <span v-if="guests == 1">гость</span> <span v-if="guests < 5 && guests > 1">гостя</span><span v-if="guests >= 5">гостей</span></span></p>
                    <a type="button" data-bs-toggle="modal" :data-bs-target="`#booking_${room.id}`">Забронировать</a>
            </div>
            <button class="button-number-details hidden btn btn-primary" type="button" data-bs-toggle="collapse"
                :data-bs-target="'#details_' + room.id" aria-expanded="false" aria-controls="collapseExample"
                @click="toggleRotateNumber">
                <p>Подробнее о номере</p>
                <img src="../assets/img/number-details.png" alt="" :class="{ 'rotate': isRotateNumber }">
            </button>
        </div>
        <div class="collapse" :id="'details_' + room.id">
            <div class="card card-body">
                <div class="possible-number-details">
                    <div class="life-rules">
                        <p>Правила проживания:</p>
                        <div class="number-timings">
                            <div class="number-check-in">
                                <p class="timing-name">Заезд</p>
                                <p>после {{ room.in_time }} </p>
                            </div>
                            <div class="number-exit">
                                <p class="timing-name">Отъезд</p>
                                <p>до {{ room.out_time }} </p>
                            </div>
                            <div class="number-minimum-stay">
                                <p class="timing-name">Минимальный срок проживания</p>
                                <p>{{ room.min_time }}</p>
                            </div>
                        </div>
                        <div class="number-basic-rules">
                            <div class="basic-rule">
                                <img src="../assets/img/baby.png" alt="">
                                <p><span v-if="kidsAviability === true">можно</span> <span
                                        v-if="kidsAviability === false">нельзя</span> с детьми</p>
                            </div>
                            <div class="basic-rule">
                                <img src="../assets/img/no-smoking.png" alt="">
                                <p>курить <span v-if="smokingAviability === true"> разрешено </span> <span v-if="smokingAviability === false"> запрещено </span> </p>
                            </div>
                            <div class="basic-rule">
                                <img src="../assets/img/pets.png" alt="">
                                <p><span v-if="petsAviability === true">можно</span> <span
                                        v-if="petsAviability === false">нельзя</span> с питомцами <span
                                        v-if="petsAviability === true"> {{
                                            howWithPets
                                        }} </span></p>
                            </div>
                            <div class="basic-rule">
                                <img src="../assets/img/document.png" alt="">
                                <p>владелец <span v-if="documentsGive === true">предоставляет</span> <span
                                        v-if="documentsGive === false">не предоставляет</span> отчетные документы о
                                    проживании</p>
                            </div>
                        </div>
                    </div>
                    <!--<div class="whats-next">
                        <p>Что рядом?</p>
                        <div class="point">
                            <div class="point-name">
                                <p>{{ firstPoint }}</p>
                                <a href="">Посмотреть на карте</a>
                            </div>
                            <div class="point-range">
                                <p> {{ firstPointRange }} км</p>
                            </div>
                        </div>
                        <div class="point">
                            <div class="point-name">
                                <p>{{ secondPoint }}</p>
                                <a href="">Посмотреть на карте</a>
                            </div>
                            <div class="point-range">
                                <p> {{ secondPointRange }} км</p>
                            </div>
                        </div>
                        <div class="point">
                            <div class="point-name">
                                <p> {{ thirdPoint }} </p>
                                <a href="">Посмотреть на карте</a>
                            </div>
                            <div class="point-range">
                                <p>{{ thirdPointRange }} км</p>
                            </div>
                        </div>
                        <button class="all-points">
                            Все места
                        </button>
                    </div>-->
                    <div class="basic-amenities">
                        <p>Основные удобства</p>
                        <div class="basic-amenities-list">
                            <div class="main-convenience" v-if="room.services.includes('Wi-Fi')">
                                <img src="../assets/img/wifi-blue.png" alt="">
                                <p>Wi-Fi</p>
                            </div>
                            <div class="main-convenience" v-if="room.services.includes('Автостоянка/парковка')">
                                <img src="../assets/img/parking-blue.png" alt="">
                                <p>Автостоянка/парковка</p>
                            </div>
                            <div class="main-convenience" v-if="room.services.includes('Сауна')">
                                <img src="../assets/img/sauna-blue.png" alt="">
                                <p>Сауна</p>
                            </div>
                            <div class="main-convenience" v-if="room.services.includes('Фен')">
                                <img src="../assets/img/hairdryer-blue.png" alt="">
                                <p>Фен</p>
                            </div>
                            <div class="main-convenience" v-if="room.services.includes('Бар/ресторан')">
                                <img src="../assets/img/tableware-blue.png" alt="">
                                <p>Бар/ресторан</p>
                            </div>
                        </div>
                        <button class="btn btn-primary" type="button" data-bs-toggle="collapse"
                            :data-bs-target="'#conveniences_' + id" aria-expanded="false"
                            aria-controls="collapseExample" @click="toggleRotateUsability">
                            <p>Посмотреть все удобства</p>
                            <img src="../assets/img/number-details.png" alt="" :class="{ 'rotate': isRotateUsability }">
                        </button>
                        <div class="collapse" :id="'conveniences_' + id">
                            <div class="card card-body">
                                <div class="conveniences">
                                    <div class="conveniences-block-left">
                                        <p>Ванная комната</p>
                                        <div class="bathroom">
                                            <p v-for="(item, index) in bathroom" :class="{'d-none': !room.services.includes(item)}" :key="index">{{ item }}</p>
                                        </div>
                                    </div>
                                    <div class="conveniences-block-right">
                                        <div class="kitchen">
                                            <p>Кухонное оборудование</p>
                                            <div class="kitchen-equipment">
                                                <p v-for="(item, index) in kitchenEquipment" :class="{'d-none': !room.services.includes(item)}" :key="index">{{ item }}</p>
                                            </div>
                                        </div>
                                        <div class="yard">
                                            <p>Оснащение двора</p>
                                            <div class="yard-equipment">
                                                <p v-for="(item, index) in yardEquipment" :class="{'d-none': !room.services.includes(item)}" :key="index">{{ item }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';

export default {
    data() {
        return {
            isRotateNumber: false,
            isRotateUsability: false,
            kidsAviability: true,
            smokingAviability: false,
            petsAviability: true,
            howWithPets: 'по согласованию с хозяином жилья',
            documentsGive: true,
            firstPoint: 'Мариинский театр',
            firstPointRange: 2.3,
            secondPoint: 'Новая Голландия',
            secondPointRange: 1.7,
            thirdPoint: 'Юсуповский дворец',
            thirdPointRange: 3.4,
            bathroom: [
                'Полотенца',
                'Туалетные принадлежности',
                'Душ',
                'Халат',
                'Тапочки',
                'Гигиенический душ',
                'Сауна',
                'Фен'
            ],
            kitchenEquipment: ['Электрический чайник', 'Холодильник'],
            yardEquipment: ['Парковка', 'Веранда', 'Терраса', 'Охраняемая территория', 'Обеденная зона на улице'],
            filter_data: {
                guests: this.$props.guests,
                room_id: this.$props.room.id,
                start: this.$props.start,
                end: this.$props.end,
                password: '',
                agent: 0,
                agent_sale: 0,
                licence: ''
            },
            promocode: ''
        };
    },
    props: {
        room: Object,
        nights: Number,
        guests: Number,
        start: String,
        end: String
    },
    methods: {
        // getImageSrc(image) {
        //     try {
        //         return require(`@/assets/img/${image}`);
        //     } catch (error) {
        //         throw new Error(error);
        //     }
        // },
        toggleRotateNumber() {
            this.isRotateNumber = !this.isRotateNumber;
        },
        toggleRotateUsability() {
            this.isRotateUsability = !this.isRotateUsability;
        },

        sendData(){
            this.filter_data.password = this.generatePassword(12)
            // this.filter_data.start =  $('.js-daterangepicker-booking').data('daterangepicker').startDate.format('YYYY-MM-DD')
            // this.filter_data.end =  $('.js-daterangepicker-booking').data('daterangepicker').endDate.format('YYYY-MM-DD')

            if(this.$props.nights >= 1){
                if(this.filter_data.agent > 0) this.filter_data.price = parseInt(this.$props.room.price*((100 - this.filter_data.agent_sale)/100))
                if(this.$store.state.user && this.filter_data.agent == 0 && this.$store.state.user_info.role == 'user')  this.filter_data.price = parseInt(this.$props.room.price*((100 - this.$store.state.user_info.sale)/100))
                if(this.$store.state.user_info.role !== 'user' && this.filter_data.agent == 0) this.filter_data.price = this.$props.room.price

                // if(this.$store.state.user) this.filter_data.price = parseInt(this.$props.room.price*((100 - this.$store.state.user_info.sale)/100))
                // else this.filter_data.price = this.$props.room.price;

                if(this.filter_data.password.length > 0 || this.$store.state.user){
                axios.post('/api/create_booking.php', this.filter_data).then((response)=>{
                    if(this.$store.state.user){
                      let price_mess = `${this.filter_data.price.toLocaleString('ru')}₽`;
                        this.$store.commit('updateSale', response.data[response.data.length-1])
                        alert('Номер забронирован!')
                        let mess = `\*Ура, у нас новое бронирование!\*\n${this.filter_data.surname} ${this.filter_data.name}\n${this.filter_data.mail}\n${this.filter_data.phone}\n${this.filter_data.licence}\n${this.$props.room.title}\n${price_mess}\nГостей: ${this.filter_data.guests}\n${new Date(this.filter_data.start).toLocaleString("ru", {year: 'numeric',month: 'long',day: 'numeric'})} - ${new Date(this.filter_data.end).toLocaleString("ru", {year: 'numeric',month: 'long',day: 'numeric'})}\n`;
                        sendTGMessage(mess)
                            function sendTGMessage(message){
                            const telegramBotToken = '7794052155:AAEx9Y2QCAssqDKCSO4RHXuf0jQsp5GdQNQ';
                            const chatId = '-1002425675515';
                            let data = {
                                chat_id: chatId,
                                text: message
                            }
                            axios.post(`https://api.telegram.org/bot${telegramBotToken}/sendMessage`, data).then(()=>window.location.reload())
                        }
                    }
                    
                    else{
                        
                        axios.post('/api/check_mail_booking.php', {mail: this.filter_data.mail}).then((response)=>{
                        alert('Номер забронирован!')
                        if(response.data == 0){
                            let mess = `\*Ура, у нас новая регистрация!\*\n${this.filter_data.name}\n${this.filter_data.surname}\n${this.filter_data.mail}\n${this.filter_data.phone}\n${this.filter_data.licence}`;
                            
                                let sendTGMessage = (message)=>{
                                    const telegramBotToken = '7794052155:AAEx9Y2QCAssqDKCSO4RHXuf0jQsp5GdQNQ';
                                    const chatId = '-1002425675515';
                                    let data = {
                                        chat_id: chatId,
                                        text: message
                                    }
                                    axios.post(`https://api.telegram.org/bot${telegramBotToken}/sendMessage`, data).then(()=>{
                                        let mess = `\*Ура, у нас новое бронирование!\*\n${this.filter_data.surname} ${this.filter_data.name}\n${this.filter_data.mail}\n${this.filter_data.phone}\n${this.filter_data.licence}\n${this.$props.room.title}\n${(this.filter_data.price).toLocaleString('ru')}₽\nГостей: ${this.filter_data.guests}\n${new Date(this.filter_data.start).toLocaleString("ru", {year: 'numeric',month: 'long',day: 'numeric'})} - ${new Date(this.filter_data.end).toLocaleString("ru", {year: 'numeric',month: 'long',day: 'numeric'})}\n`;
                                        sendTGMessage1(mess)
                                            function sendTGMessage1(message){
                                            const telegramBotToken = '7794052155:AAEx9Y2QCAssqDKCSO4RHXuf0jQsp5GdQNQ';
                                            const chatId = '-1002425675515';
                                            let data = {
                                                chat_id: chatId,
                                                text: message
                                            }
                                            axios.post(`https://api.telegram.org/bot${telegramBotToken}/sendMessage`, data).then(()=>window.location.reload())
                                        }
                                    })
                                }
                                sendTGMessage(mess)
                            }
                            else{
                                        let mess = `\*Ура, у нас новое бронирование!\*\n${this.filter_data.surname} ${this.filter_data.name}\n${this.filter_data.mail}\n${this.filter_data.phone}\n${this.filter_data.licence}\n${this.$props.room.title}\n${(this.filter_data.price).toLocaleString('ru')}₽\nГостей: ${this.filter_data.guests}\n${new Date(this.filter_data.start).toLocaleString("ru", {year: 'numeric',month: 'long',day: 'numeric'})} - ${new Date(this.filter_data.end).toLocaleString("ru", {year: 'numeric',month: 'long',day: 'numeric'})}\n`;
                                        sendTGMessage(mess)
                                            function sendTGMessage(message){
                                            const telegramBotToken = '7794052155:AAEx9Y2QCAssqDKCSO4RHXuf0jQsp5GdQNQ';
                                            const chatId = '-1002425675515';
                                            let data = {
                                                chat_id: chatId,
                                                text: message
                                            }
                                            axios.post(`https://api.telegram.org/bot${telegramBotToken}/sendMessage`, data).then(()=>window.location.reload())
                                        }
                            }
                        })
                    }
                    
                })
                }
                else alert('Придумайте пароль для доступа в личный кабинет!')
            }
            else{
                alert('Некорректное количество ночей!')
            }
        
        },

        checkUserMail(){
            if(this.$store.state.user == false){
                axios.post('/api/check_mail_booking.php', {mail: this.filter_data.mail}).then((response)=>{
                if(response.data == 1){
                    alert('Вы уже зарегистрированы на нашем сайте! Авторизуйтесь в личном кабинете, чтобы воспользоваться персональной скидкой нашей программы лояльности!')
                }
                })
            }
        },

        usePromocode(){
            axios.post('/api/check_promocode.php', {code: this.promocode}).then((response)=>{
                if(response.data == 0){
                alert('Такого промокода не существует')
                this.promocode = '';
                this.filter_data.agent_sale = 0
                this.filter_data.agent = 0
                }
                else{
                this.filter_data.agent_sale = response.data[0]
                this.filter_data.agent = response.data[1]

                console.log(this.filter_data)
                }
            })
        },

        generatePassword(len){
            var password = "";
            var symbols = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!№;%:?*()_+=";
            for (var i = 0; i < len; i++){
                password += symbols.charAt(Math.floor(Math.random() * symbols.length));     
            }
            return password;
        }
    },

    watch: {
        start:{
        handler(newVal){
            this.filter_data.start = newVal
        },
        deep: true
        },
        end:{
        handler(newVal){
            this.filter_data.end = newVal
            console.log(newVal)
        },
        deep: true
        },
        guests:{
        handler(newVal){
            this.filter_data.guests = newVal
        },
        deep: true
        },
    },

    created(){
        if(this.$store.state.user){
        this.filter_data.name = this.$store.state.user_info.name
        this.filter_data.phone = this.$store.state.user_info.phone
        this.filter_data.mail = this.$store.state.user_info.mail
        this.filter_data.surname = this.$store.state.user_info.surname
        this.filter_data.licence = this.$store.state.user_info.licence

        }
    },

    mounted(){
        IMask(document.getElementById(`phone-booking${this.$props.room.id}`), {
            mask: '+{7}(000)000-00-00'
        });
    }
}
</script>