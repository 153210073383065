<template>
  <div class="container-reservations">
    <div class="action-buttons">
      <button :class="{ 'active-button': activeActionButton === 'reservations' }"
        @click="setActiveActionButton('reservations')">Бронирования</button>
      <!-- <button :class="{ 'active-button': activeActionButton === 'favourites' }"
        @click="setActiveActionButton('favourites')">Избранное</button> -->
      <button :class="{ 'active-button': activeActionButton === 'bonuses' }"
        @click="setActiveActionButton('bonuses')">Бонусы</button>
      <button :class="{ 'active-button': activeActionButton === 'settings' }"
        @click="setActiveActionButton('settings'); setPhoneMask()">Настройки</button>
      <button @click="exitUser()">Выйти</button>
    </div>
    <div class="discount-system" v-if="activeActionButton === 'reservations'">
      <div class="discounts">
        <p class="discounts-heading">Накопительная система скидок!</p>
        <p>после первого бронирования скидка 3%</p>
        <p>после второго – скидка 4%</p>
        <p>после третьего – скидка 5%</p>
        <p>после четвертого – скидка 6%</p>
        <p>после пятого – скидка 7%</p>
      </div>
      <div class="present">
        <img src="../assets/img/present.png" alt="">
      </div>
    </div>
    <div class="reserved-hotels" v-if="activeActionButton === 'reservations'">
      <div class="reservations-buttons">
        <button :class="{ 'active-button': activeReservationButton === 'all' }"
          @click="setActiveReservationButton('all')">Все</button>
        <button :class="{ 'active-button': activeReservationButton === 'reserved' }"
          @click="setActiveReservationButton('reserved')">Действительные</button>
        <button :class="{ 'active-button': activeReservationButton === 'archive' }"
          @click="setActiveReservationButton('archive')">В архиве</button>
        <!-- <button :class="{ 'active-button': activeReservationButton === 'canceled' }"
          @click="setActiveReservationButton('canceled')">Отмененные</button> -->
      </div>
      <div class="reservations-hotels">
        <ReservationHotel v-for="(hotel, index) in filteredHotels" :key="index" :reserved="hotel.reserved"
          :image="hotel.image" :name="hotel.name" :bed="hotel.bed"
          :eatAvailability="hotel.eatAvailability" :wifiAviability="hotel.wifiAviability" :price="hotel.price"
          :nights="Number(hotel.nights)" :guests="Number(hotel.guests)" :dateCheckIn="hotel.dateCheckIn"
          :dateCheckOut="hotel.dateCheckOut" :id="hotel.room_id" :date="hotel.date" :address="hotel.address" :agent_sale="hotel.agent_sale"/>
      </div>
    </div>
    <div class="container-bonuses" v-if="activeActionButton === 'bonuses'">
      <div class="main-block">
        <div class="stats-block">
          <p>Статистика</p>
          <div class="stats" v-bind:data-orders="orders" v-bind:data-discount="discount">
            <div class="stat-order">
              <p>Всего заказов</p>
              <p class="orders"> {{ orders }} </p>
            </div>
            <div class="stat-discount">
              <p>Скидка</p>
              <p class="discount">{{ userInfo.sale }} %</p>
            </div>
          </div>
        </div>
        <div class="programm-block">
          <p>Описание программы</p>
          <div class="programm-bonuses">
            <p>после <span>первого </span>бронирования скидка 3%</p>
            <p>после <span>второго </span>– скидка 4%</p>
            <p>после <span>третьего </span>– скидка 5%</p>
            <p>после <span>четвертого </span>– скидка 6%</p>
            <p>после <span>пятого </span>– скидка 7%</p>
            <p class="discount-text">Скидка применяется автоматически при каждом новом бронировании</p>
          </div>
        </div>
      </div>
      <!-- <div class="discount-details">
        <p>Детализация активации скидок</p>
        <div class="details">
          <div class="detail" v-for="(detail, index) in details" :key="index">
            <div class="date">
              <p>{{ detail.date }}</p>
            </div>
            <div class="discount">
              <p>{{ detail.discount }}%</p>
            </div>
            <div class="for-what">
              <p> {{ detail.forWhat }} </p>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <div class="container-settings" v-if="activeActionButton === 'settings'">
      <div class="settings-block">
        <div class="about-you-block">
          <p>Личные данные</p>
          <div class="about-you-settings">
            <div class="your-name">
              <p>Имя: </p>
              <input type="text" name="" id="" placeholder="Ваше имя" required class="input-field" v-model="userInfo.name">
            </div>
            <div class="your-surname">
              <p>Фамилия: </p>
              <input type="text" name="email" placeholder="Ваша фамилия" required class="input-field" v-model="userInfo.surname">
            </div>
            <div class="your-mail">
              <p>Электронный адрес: </p>
              <input type="email" id="email" name="mail" placeholder="E-mail" required class="input-field" v-model="userInfo.mail">
            </div>
            <div class="your-country">
              <p>Телефон: </p>
              <div class="country-input">
                <input type="tel" id="phone" name="phone" placeholder="Номер телефона" required class="input-field" v-model="userInfo.phone">
              </div>
            </div>
            <div class="your-city">
              <p>Паспорт: </p>
              <input type="text" name="" id="" placeholder="Серия и номер паспорта" required class="input-field" v-model="userInfo.licence">
            </div>
            <button class="save-settings" @click="editProfile">Сохранить</button>
          </div>
        </div>
        <div class="password-block">
          <p>Изменить пароль</p>
          <div class="password">
            <input type="password" name="" id="main-password" placeholder="Текущий пароль" required class="input-field" v-model="newPassword.old">
            <input type="password" name="" id="new-password" placeholder="Новый пароль" required class="input-field" v-model="newPassword.new">
            <input type="password" name="" id="repeat-new-password" placeholder="Новый пароль еще раз" required class="input-field" v-model="newPassword.newAccept">
            <button @click="changePassword">Изменить пароль</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReservationHotel from '@/components/ReservationHotel.vue';
import axios from 'axios';

export default {
  components: {
    ReservationHotel,
  },
  data() {
    return {
      details: [
        {
          date: '07.08.2024',
          discount: 2,
          forWhat: 'Бронирование отеля'
        },
        {
          date: '09.09.2024',
          discount: 3,
          forWhat: 'Бронирование отеля'
        },
        {
          date: '15.09.2024',
          discount: 3,
          forWhat: 'Бронирование отеля'
        }
      ],
      orders: 0,
      discount: 0,
      ReservationHotels: [
       
      ],
      filteredHotels: [],
      activeReservationButton: 'all',
      activeActionButton: 'reservations',
      userInfo: {
        'id': 0,
        'name': '',
        'surname': '',
        'mail': '',
        'phone': '',
        'licence': '',
        'password': '',
        'role': '',
        'sale': 0
      },
      newPassword: {
        old: '',
        new: '',
        newAccept: ''
      }
    }
  },
  // mounted() {
  //   this.showAll(); // Показать все отели по умолчанию
  // },
  methods: {
    showAll() {
      this.filteredHotels = this.ReservationHotels;
    },
    showReserved(reserved) {
      this.filteredHotels = this.ReservationHotels.filter(hotel => {
        if (reserved === null) {
          return hotel.reserved === null; // В архиве
        } else {
          return hotel.reserved === reserved; // Действительные или Отмененные
        }
      });
    },
    setActiveReservationButton(button) {
      this.activeReservationButton = button; // Устанавливаем новую активную кнопку

      // Вызываем соответствующий метод фильтрации
      if (button === 'all') {
        this.showAll();
      } else if (button === 'reserved') {
        this.showReserved(true);
      } else if (button === 'archive') {
        this.showReserved(null);
      } else if (button === 'canceled') {
        this.showReserved(false);
      }
    },
    setActiveActionButton(button) {
      this.activeActionButton = button; // Устанавливаем новую активную кнопку
    },

    editProfile(){
      if(confirm('Вы точно хотите изменить персональные данные?')){
        axios.post('/api/edit_user.php', this.userInfo).then((response)=>{
          if(response.data == 1){
            this.$store.commit('updateUser', this.userInfo)
            alert('Успешно')
            window.location.reload()
          }
          else{
            alert('Пользователь с таким Email уже существует!')
          }
        })
      }
    },

    changePassword(){
      if(this.newPassword.old == this.$store.state.user_info.password && this.newPassword.new == this.newPassword.newAccept && this.newPassword.new.length > 0){
        if(confirm('Вы точно хотите изменить пароль?')){
          this.newPassword.id = this.$store.state.user_info.id
          axios.post('/api/change_password.php', this.newPassword).then((response)=>{
            if(response.status == 200){
              this.$store.commit('updatePassword', this.newPassword.new)
              alert('Успешно')
              window.location.reload()
            }
          })
        }
      }
      else{
        this.newPassword = {old: '', new: '', newAccept: ''}
        alert('Старый пароль введен неверно или пароли не совпадают, проверьте данные и повторите попытку!')
      }
    },

    setPhoneMask(){
      setTimeout(()=>{IMask(document.getElementById('phone'), {
        mask: '+{7}(000)000-00-00'
      });
      },
      1000
      )
    },

    loadData(){
      // axios.post('/api/get_user_data.php', {id: this.$store.state.user_info.id, mail: this.$store.state.user_info.mail}).then((response)=>{
      //   console.log(response.data)
      //   this.orders = response.data.length;
      // })

      axios.post('/api/get_reservations.php', {mail: this.$store.state.user_info.mail}).then((response)=>{
        console.log(response.data)
        for(let i=0; i<response.data.length; i++){
          response.data[i].nights = Number((new Date(response.data[i].dateCheckOut) - new Date(response.data[i].dateCheckIn)) / (1000 * 60 * 60 * 24));
          if(new Date(response.data[i].dateCheckOut) < new Date()) response.data[i].reserved = null
          else response.data[i].reserved = true
          response.data[i].name = response.data[i].title + ' ' + response.data[i].hotel_title
          if(response.data[i].wifiAviability == '1') response.data[i].wifiAviability = true
          else response.data[i].wifiAviability = false
          if(response.data[i].eatAvailability == '1') response.data[i].eatAvailability = true
          else response.data[i].eatAvailability = false
        }
        this.orders = response.data.length
        this.ReservationHotels = response.data
        this.showAll(); // Показать все отели по умолчанию
      })
    },

    exitUser(){
      this.$store.commit('exitUser');
      window.location.href = '/auth?role=user'
    }

  },

  created(){
    if(this.$store.state.user == false){
      alert('Войдите в личный кабинет!')
      window.location.href = '/auth?role=user'
    }
    this.userInfo = this.$store.state.user_info
    this.loadData();
  }
}
</script>