import HotelPage from '@/views/HotelPage.vue'
import HotelsList from '@/views/HotelsList.vue'
import MainPage from '@/views/MainPage.vue'
import LkPage from '@/views/LkPage.vue'
import { createRouter, createWebHistory } from 'vue-router'
import RegPage from '@/views/RegPage.vue'
import AuthPage from '@/views/AuthPage.vue'
import LkAgentPage from '@/views/LkAgentPage.vue'
import TestMapLeaflet from '@/views/TestMapLeaflet.vue'
const routes = [
  {
    path: '/',
    component: MainPage
  },
  {
    path: '/hotels',
    component: HotelsList
  },
  {
    path: '/hotels/:hotel_id',
    component: HotelPage,
    props: true
  },
  {
    path: '/lk',
    component: LkPage
  },
  {
    path: '/reg',
    component: RegPage
  },
  {
    path: '/auth',
    component: AuthPage
  },
  {
    path: '/lk_agent',
    component: LkAgentPage
  },
  {
    path: '/test_map',
    component: TestMapLeaflet
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router