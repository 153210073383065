<template>
    <footer id="contacts">
        <div class="footer">
            <div class="footer-info">
                <div class="footer-logo">
                    <a href="/"><img src="../assets/img/company-logo.png" alt=""></a>
                    <p>Московский Отельер</p>
                    <p>© 2024</p>
                    <p class="mt-4"><a href="mailto:moshot@moshot.ru">moshot@moshot.ru</a></p>
                    <p><a href="tel:+79251444764">+7 (925) 144 47 64</a></p>
                    <p class="mt-4" style="font-size: 0.8em;">ООО «РОБ»</p>
                    <p style="font-size: 0.8em;">644030, г. Омск, ул. Полторацкого, <br> д. 50, кв. 265.</p>
                    <p style="font-size: 0.8em;">ИНН: 5505072221</p>
                    <p style="font-size: 0.8em;">ОГРН: 1235500018830</p>
                </div>
                <div class="footer-anchors">
                    <a href="/">Главная</a>
                    <a href='/hotels'>Отели и номера</a>
                    <a href="/lk?role=user">Личный кабинет гостя</a>
                    <a href="/lk?role=agent">Личный кабинет агента</a>
                    <a href="#" onclick="alert('Скоро появится на сайте!')">Авиабилеты</a>
                </div>
                <div class="footer-description">
                    <p class="footer-description-heading">Ваш надежный партнер в бронировании отелей в Москве</p>
                    <p>Мы предлагаем удобный и быстрый сервис для бронирования отелей в Москве. Наша компания гарантирует
                        лучшие цены, прозрачные условия и круглосуточную поддержку. Доверьтесь нам, и ваше пребывание в
                        Москве будет комфортным и приятным!</p>
                </div>
            </div>
            <div class="footer-political-block">
                <div class="footer-political">
                    <a href="/docs/policy.pdf" target="_blank">Политика конфиденциальности</a>
                    <a href="/docs/офферта.pdf" target="_blank">Договор офферты</a>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {

}
</script>