<template>
    <div class="container-find">

        <form class="filters-container mb-4" style="z-index: 2;" @submit.prevent="applyFilter()">
            <div class="filters-block w-100">
                <input type="text" class="date px-3 my-auto w-100" placeholder="Введите название отеля или номера" v-model="filter_data.search">
                <div class="filters-find w-100">
                    <button class="find w-100">Поиск</button>
                </div>
            </div>
        </form>

        <form class="filters-container" style="z-index: 2;" @submit.prevent="applyFilter()">
            <div class="filters-block">
                <button class="filters hidden">
                    <p>Фильтры</p>
                    <img src="../assets/img/filter-sliders-black.png" alt="">
                </button>
                <div class="date hidden">
                    <!--<input type="text" placeholder="Дата заезда и выезда" required class="input-field">
                    <button>
                        <img src="../assets/img/filter-calendar.png" alt="">
                    </button>-->
                    
                </div>
                <div class="date">
                    <!--<input type="date" placeholder="Дата заезда и время выезда" required class="input-field">
                    
                    <button>
                        <img src="../assets/img/filter-calendar.png" alt="">
                    </button>-->
                    <div class="input-group">
                    <input type="text" class="form-control pageFilter__daterange js-daterangepicker" placeholder="Введите даты" id="period_a0acfa46" autocomplete="off"><span class="input-group-text" style="border: 0; background: transparent;"><label for="period_a0acfa46"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar3" viewBox="0 0 16 16"><path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z"></path><path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"></path></svg></label></span></div>
                    <input type="hidden" name="filter[start_period]" class="js-daterangepicker__start" value="10.02.2024">
                    <input type="hidden" name="filter[end_period]" class="js-daterangepicker__end" value="10.03.2024">
                </div>
                <!--<div class="guests">
                    <input type="text" placeholder="Количество гостей" required class="input-field">
                    <button>
                        <img src="../assets/img/filter-arrow.png" alt="">
                    </button>
                </div>-->

                <details class="custom-select guests" id="guestsSelect">
                <summary class="radios">
                    <input type="radio" name="item" id="default_guest" title="Количество гостей" value="0" checked v-model="filter_data.guests" onclick="document.querySelector('#guestsSelect').open = !document.querySelector('#guestsSelect').open">
                    <input type="radio" name="item" id="guest1" title="1 гость" value="1" v-model="filter_data.guests" onclick="document.querySelector('#guestsSelect').open = !document.querySelector('#guestsSelect').open">
                    <input type="radio" name="item" id="guest2" title="2 гостя" value="2" v-model="filter_data.guests" onclick="document.querySelector('#guestsSelect').open = !document.querySelector('#guestsSelect').open">
                    <input type="radio" name="item" id="guest3" title="3 гостя" value="3" v-model="filter_data.guests" onclick="document.querySelector('#guestsSelect').open = !document.querySelector('#guestsSelect').open">
                    <input type="radio" name="item" id="guest4" title="4 гостя" value="4" v-model="filter_data.guests" onclick="document.querySelector('#guestsSelect').open = !document.querySelector('#guestsSelect').open">
                    <input type="radio" name="item" id="guest5" title="5+ гостей" value="5" v-model="filter_data.guests" onclick="document.querySelector('#guestsSelect').open = !document.querySelector('#guestsSelect').open">
                </summary>
                <ul class="list">
                    <li>
                        <label for="guest1">
                            1 гость
                            <span></span>
                        </label>
                    </li>
                    <li>
                        <label for="guest2">2 гостя</label>
                    </li>
                    <li>
                        <label for="guest3">3 гостя</label>
                    </li>
                    <li>
                        <label for="guest4">4 гостя</label>
                    </li>
                    <li>
                        <label for="guest5">5+ гостей</label>
                    </li>
                </ul>
            </details>
                <!--<div class="moscow-district">
                    <input type="text" placeholder="Район Москвы" required class="input-field">
                </div>-->

                <details class="custom-select moscow-district" id="disSelect">
                    <summary class="radios">
                        <input type="radio" name="dis" id="default_moscow" title="Все районы Москвы" value="Все" v-model="filter_data.district" onclick="document.querySelector('#disSelect').open = !document.querySelector('#disSelect').open">
                        <input type="radio" name="dis" :id="`dis${index}`" :value="dis" :title="dis" v-model="filter_data.district" v-for="(dis, index) in districts" :key="index" onclick="document.querySelector('#disSelect').open = !document.querySelector('#disSelect').open">
                    </summary>
                    <ul class="list">
                        <li>
                            <label for="default_moscow">Все районы Москвы</label>
                        </li>
                        <li v-for="(dis, index) in districts" :key="index">
                            <label :for="`dis${index}`">{{ dis }}</label>
                        </li>
                    </ul>
                </details>

                <div style="position: relative;" class="filters-container-relative">
                    <button class="filters visible" type="button" data-bs-toggle="collapse" data-bs-target="#service_params">
                        <p>Фильтры</p>
                        <img src="../assets/img/filter-sliders-black.png" alt="">
                    </button>
                    <div class="checkbox-collapse-list collapse" id="service_params">
                        <div class="card card-body" >
                            <div class="form-check" v-for="(service, index) in servicesList" :key="index">
                                <input class="form-check-input" type="checkbox" :id="`${index}_service`" :value="service" v-model="filter_data.services">
                                <label class="form-check-label" :for="`${index}_service`">
                                    {{ service }}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <!--<div class="price-filter">
                    <div class="price-filter-text">
                        <p class="price-one-night">Цена за ночь</p>
                        <p>От 1245 ₽</p>
                    </div>
                    <button>
                        <img src="../assets/img/back-button.png" alt="">
                    </button>
                </div>-->

                <div style="position: relative;" class="filters-container-relative">
                    <div class="price-filter" type="button" data-bs-toggle="collapse" data-bs-target="#price_params">
                        <div class="price-filter-text">
                            <p class="price-one-night">Цена за ночь</p>
                            <p style="font-size: 0.9em;"><span v-if="filter_data.prices.minPrice">{{filter_data.prices.minPrice}}₽ </span> - <span v-if="filter_data.prices.maxPrice">{{filter_data.prices.maxPrice}}₽</span></p>
                        </div>
                    </div>
                    <div class="checkbox-collapse-list collapse" id="price_params">
                        <div class="card card-body" >
                                <div class="mb-3">
                                    <label for="priceFromInput" style="font-size: 0.9em;" class="form-label me-3">Цена от</label>
                                    <div class="d-flex">
                                        <input type="number" v-model="filter_data.prices.minPrice" class="form-control px-2 py-1" style="width: 100px;" id="priceFromInput">
                                        <label for="priceFromInput" class="form-label ms-2 my-auto">₽</label>
                                    </div>
                                </div>
                                <div class="">
                                    <label for="priceToInput" style="font-size: 0.9em;" class="form-label me-3">Цена до</label>
                                    <div class="d-flex">
                                        <input type="number" v-model="filter_data.prices.maxPrice" class="form-control px-2 py-1" style="width: 100px;" id="priceToInput">
                                        <label for="priceToInput" class="form-label ms-2 my-auto">₽</label>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>

                <!--<div class="food-filter">
                    <div>
                        <p class="food-filter-text">Питание</p>
                        <p>Завтрак включен</p>
                    </div>
                    <button>
                        <img src="../assets/img/back-button.png" alt="">
                    </button>
                </div>-->

                <!--<div class="stars-filter">
                    <div class="stars-filter-text">
                        <p>Звезды</p>
                        <div class="stars">
                            <img src="../assets/img/star.png" alt="">
                            <img src="../assets/img/star.png" alt="">
                            <img src="../assets/img/star.png" alt="">
                        </div>
                    </div>
                    <button>
                        <img src="../assets/img/back-button.png" alt="">
                    </button>
                </div>-->

                <div style="position: relative;" class="filters-container-relative">
                    <div class="stars-filter" type="button" data-bs-toggle="collapse" data-bs-target="#stars_params">
                        <div class="stars-filter-text d-flex flex-row mb-0">
                            <p>Количество звезд </p>
                            <img src="../assets/img/star.png" alt="" style="width: 1em; height: 1em;">
                        </div>
                    </div>
                    <div class="checkbox-collapse-list collapse" id="stars_params">
                        <div class="card card-body" >
                            <!--<div class="form-check d-flex mb-2">
                                <input class="form-check-input my-auto me-2 p-0" type="checkbox" id="0_stars" value="0" v-model="filter_data.stars">
                                <label class="form-check-label" style="margin-top: 1.5px;" for="0_stars">
                                    Без звезд
                                </label>
                            </div>-->
                            <div class="form-check d-flex mb-2" v-for="(stars, index) in [3, 4, 5]" :key="index">
                                <input class="form-check-input my-auto me-1 p-0" type="checkbox" :id="`${stars}_stars`" :value="stars" v-model="filter_data.stars">
                                <label class="form-check-label d-flex my-auto" :for="`${stars}_stars`">
                                    <img v-for="star in stars" :key="star" src="../assets/img/star.png" alt="" style="width: 1em; height: 1em; margin-right: 5px;">
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="position: relative;" class="filters-container-relative">
                    <div class="stars-filter" type="button" data-bs-toggle="collapse" data-bs-target="#bed_types_params">
                        <div class="stars-filter-text d-flex flex-row mb-0">
                            <p>Тип кроватей </p>
                            
                        </div>
                    </div>
                    <div class="checkbox-collapse-list collapse" id="bed_types_params">
                        <div class="card card-body" >
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="bed_types" id="bed_types1" value="Двуспальная кровать" v-model="filter_data.bed_types">
                                <label class="form-check-label" for="bed_types1">
                                    Двуспальная кровать
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="bed_types" value="Раздельные кровати" id="bed_types2" v-model="filter_data.bed_types">
                                <label class="form-check-label" for="bed_types2">
                                    Раздельные кровати
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="filters-find">
                <button class="find">Найти</button>
            </div>
        </form>

        <div class="hotels">
            <p>Отели и номера в Москве</p>
            <div class="hotels-block">
                <HotelCard v-for="(room, index) in rooms" :key="index" :room="room" :nights="nights_card" :guests="guests_card" :start="filter_data.start" :end="filter_data.end"/>
            </div>
            <div class="hotels-slider">
                <button class="prev-slide" @click="prevPage" :disabled="currentPage === 1">
                    <img src="../assets/img/hotel-slide.png" alt="" />
                </button>
                <button v-for="page in count_pages" :key="page" :class="{ 'active-button': currentPage === page }"
                    @click="goToPage(page)">
                    {{ page }}
                </button>
                <button class="next-slide" @click="nextPage" :disabled="currentPage === totalPages">
                    <img src="../assets/img/hotel-slide.png" alt="" />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import HotelCard from '@/components/HotelCard.vue';
import axios from 'axios';

export default {
    components: {
        HotelCard,
    },
    data() {
        return {
            currentPage: 1,
            itemsPerPage: 4,
            rooms: [],
            servicesList: [
                'Wi-Fi',
                'Автостоянка/парковка',
                'Сауна',
                'Фен',
                'Бар/ресторан',
                'Полотенца',
                'Туалетные принадлежности',
                'Душ',
                'Халат',
                'Тапочки',
                'Гигиенический душ',
                'Электрический чайник',
                'Холодильник',
                'Веранда',
                'Терраса',
                'Охраняемая территория',
                'Обеденная зона на улице',
                'Завтрак включен'
            ],
            districts: [
                'Центр',
                'Север',
                'Северо-запад',
                'Северо-восток',
                'Запад',
                'Юг',
                'Юго-запад',
                'Юго-восток',
                'Восток'
            ],
            filter_data: {
                district: 'Все',
                services: [],
                prices: {
                    minPrice: 0,
                    maxPrice: 0
                },
                stars: [],
                guests: "0",
                bed_types: "",
                page: 0
            },
            guests_card: "1",
            nights_card: "7",
            count_pages: 0
        }
    },
    computed: {
        // paginatedHotels() {
        //     const start = (this.currentPage - 1) * this.itemsPerPage;
        //     const end = start + this.itemsPerPage;
        //     return this.hotel.slice(start, end);
        // },
        // totalPages() {
        //     return Math.ceil(this.hotel.length / this.itemsPerPage);
        // },
    },
    methods: {
        goToPage(page) {
            this.currentPage = page;
            this.filter_data.page = page-1
            this.loadData()
            
        },

        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
                this.filter_data.page = this.currentPage-1
                this.loadData()
            }
        },

        nextPage() {
            if (this.currentPage < 10) {
                this.currentPage++;
                this.filter_data.page = this.currentPage-1
                this.loadData()
            }
        },

        loadData(){
            
            console.log(this.filter_data)
            axios.post('/api/get_hotels_rooms.php', this.filter_data).then((response)=>{       
                
                this.count_pages = Math.floor(parseInt(response.data[2])/10) +1
                   
                for(let i=0; i<response.data[0].length; i++){
                    for(let j=i+1; j<response.data[0].length; j++){
                        if(response.data[0][i].room_title == response.data[0][j].room_title && response.data[0][i].hotel_id == response.data[0][j].hotel_id){
                            response.data[0].splice(j, 1)
                            j--
                        }
                    }
                }

                this.rooms = response.data[0]
                console.log(response.data)
                // this.filter_data.prices.minPrice = response.data[1][0]
                // this.filter_data.prices.maxPrice = response.data[1][1]
                this.nights_card = Number((new Date(this.filter_data.end) - new Date(this.filter_data.start)) / (1000 * 60 * 60 * 24));
                this.guests_card = Number(this.filter_data.guests)
            })
            
            
        },

        applyFilter(){
            this.filter_data.end = $('.js-daterangepicker').data('daterangepicker').endDate.format('YYYY-MM-DD')
            this.filter_data.start = $('.js-daterangepicker').data('daterangepicker').startDate.format('YYYY-MM-DD')
            this.loadData()
        },

        // applySearch(){
        //     if(this.filter_search.length > 0){
        //         window.location.href=`/hotels?search=${this.filter_search}&start=${this.filter_data.start}&end=${this.filter_data.end}`
        //     }
        // }
    },

    created(){
        this.filter_data.end =  moment().add(2, 'days').format('YYYY-MM-DD');
        this.filter_data.start = moment().add(1, 'days').format('YYYY-MM-DD');
       

        let params = window
        .location
        .search
        .replace('?','')
        .split('&')
        .reduce(
            function(p,e){
                var a = e.split('=');
                p[ decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
                return p;
            },
            {}
        );

        if(params.start){
            this.filter_data.start = params.start
           
        }
        if(params.end){
            this.filter_data.end = params.end
            
        }
        if(params.search){
            this.filter_data.search = params.search
        }
        if(params.guests) this.filter_data.guests = params.guests
        if(params.services) this.filter_data.services = JSON.parse(params.services)
        if(params.district) this.filter_data.district = params.district
        if(params.stars) this.filter_data.stars = JSON.parse(params.stars)
        if(params.bed_types) this.filter_data.bed_types = params.bed_types
        if(params.minPrice && params.minPrice > 0) this.filter_data.prices.minPrice = params.minPrice
        if(params.maxPrice && params.maxPrice > 0) this.filter_data.prices.maxPrice = params.maxPrice

        console.log(this.filter_data)
        this.loadData()
    },

    mounted(){
        let start = moment().add(2, 'days');
        let end = moment().add(1, 'days');
        if(this.filter_data.start) start = moment(this.filter_data.start)
        if(this.filter_data.end) end = moment(this.filter_data.end)

        $('.js-daterangepicker').daterangepicker({
        "locale": {
            "format": "DD.MM.YYYY",
            "separator": " - ",
            "applyLabel": "Сохранить",
            "cancelLabel": "Назад",
            "daysOfWeek": [
                "Вс",
                "Пн",
                "Вт",
                "Ср",
                "Чт",
                "Пт",
                "Сб"
            ],
            "monthNames": [
                "Январь",
                "Февраль",
                "Март",
                "Апрель",
                "Май",
                "Июнь",
                "Июль",
                "Август",
                "Сентябрь",
                "Октябрь",
                "Ноябрь",
                "Декабрь"
            ],
            "firstDay": 1
        },
        
        opens: 'left',
        autoUpdateInput: false,
        startDate: start,
        endDate: end,
        minDate: moment(),
        }, (start, end) => {
            $('.js-daterangepicker').data('daterangepicker').startDate = start
            $('.js-daterangepicker').data('daterangepicker').endDate = end
            this.filter_data.start = start.format('YYYY-MM-DD')
            this.filter_data.end =  end.format('YYYY-MM-DD')
        });

        $('#period_a0acfa46').on('apply.daterangepicker', function(ev, picker) {
            $(this).val(picker.startDate.format('DD.MM.YYYY') + ' - ' + picker.endDate.format('DD.MM.YYYY'));
        });

        $('li[data-range-key="Custom Range"]').text('Произвольно')
    }
}
</script>

<style lang="scss" scoped>
#period_a0acfa46::placeholder{
  color: #000;
  opacity: 0.9;
}
</style>